import * as myData from '../json/data.json';
import { Modal } from 'bootstrap';
import * as _ from 'lodash-es';
import $ from 'jquery';
import 'slick-carousel';
/**
 * class qui permet de créer des éléments HTML (ou Node)
 */
class Dynamically_create_element {
    constructor(data) {
        this.data = data;
    }
    create() {
        const element = document.createElement(this.data.tagName);
        if (this.data.attributes) {
            let key; // add this declaration
            for (key in this.data.attributes) {
                element.setAttribute(key, this.data.attributes[key]); // works
            }
        }
        if (this.data.onclick) {
            element.onclick = this.data.onclick;
        }
        if (this.data.classList) {
            element.classList.add(...this.data.classList);
        }
        element.innerHTML = this.data.innerHTML || '';
        return element;
    }
}
let DONATION;
const URL_DON = 'https://dons.fnh.org/FNH-Enquete23';
const TIMEOUT_MODAL = 500;
const QUIZ = [];
let STEP = 0;
const ANSWER_REGISTERED = [];
let PARAMS_FROM_URL = {};
let MODAL;
let IP;
let MAIL;
let COUNT = 0;
let TRY_NUMBER = 0;
const MAX_TRY_NUMBER = 20; // TODO change when we know how  many try is allowed
let USER = { ip: null, mail: null, tryNumber: 0, done: '0' };
let USERS = [];
let QUIZ_SAVED_IN_DB_ID;
window.onload = () => initialize();
function initialize() {
    PARAMS_FROM_URL = getAllUrlParams();
    MAIL = (PARAMS_FROM_URL === null || PARAMS_FROM_URL === void 0 ? void 0 : PARAMS_FROM_URL.mail) || '';
    if (MAIL && MAIL !== '') {
        createEvent({ mail: MAIL, done: '0' });
    }
    fetch('https://api.ipify.org/?format=json')
        .then((res) => res.json())
        .then((res) => {
        IP = res.ip.toString();
        USER = { ip: IP, mail: MAIL, tryNumber: 0, done: '0' };
        const myFunction = () => sendDataToUserSession(fetchUsersWithSameIpAdresse);
        unsetSession(myFunction);
    });
    MODAL = createModal();
    const dropdownBtn = document.querySelector('.drop-btn');
    if (dropdownBtn) {
        dropdownBtn.onclick = () => {
            const dropdownContent = document.querySelector('.dropdown-content');
            dropdownContent === null || dropdownContent === void 0 ? void 0 : dropdownContent.classList.toggle('show');
        };
    }
    initBtnSocialMedia();
}
function unsetSession(callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'unset-session.php', true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            if (callback) {
                callback();
            }
        }
    };
    xhr.setRequestHeader('Content-type', 'application/json'); // or "text/plain"
    xhr.send();
}
function sendDataToUserSession(callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'session-user.php', true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            if (callback) {
                callback();
            }
        }
    };
    xhr.setRequestHeader('Content-type', 'application/json'); // or "text/plain"
    xhr.send(JSON.stringify(USER));
}
function getUserLastInsert(callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'get-user-last-insert.php', true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            // todo il faut recuperer les info de la reponse pour avoir l'id de l'user
            if (xhr.responseText) {
                USERS = JSON.parse(xhr.responseText);
                if (USERS[USERS.length - 1]) {
                    USER = USERS[USERS.length - 1];
                    sendDataToUserSession();
                }
            }
            if (callback) {
                callback();
            }
        }
    };
    xhr.setRequestHeader('Content-type', 'application/json'); // or "text/plain"
    xhr.send();
}
function addUser(callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'add-user.php', true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            // todo il faut recuperer les info de la reponse pour avoir l'id de l'user
            if (callback) {
                callback();
            }
        }
    };
    xhr.setRequestHeader('Content-type', 'application/json'); // or "text/plain"
    xhr.send();
}
function fetchAllUsersCount() {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', 'fetch-all-users-count.php', true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            COUNT = JSON.parse(xhr.responseText);
            const countToDisplay = JSON.parse(xhr.responseText) + 3015;
            const elem = document.querySelector('#number-of-users');
            if (elem) {
                elem.innerText = countToDisplay.toString();
            }
            initializeBtnStart();
        }
    };
    xhr.setRequestHeader('Content-type', 'application/json'); // or "text/plain"
    xhr.send();
}
function fetchUsersWithSameIpAdresse() {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', 'fetch-users-with-same-ip-adresse.php', true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            if (xhr.response) {
                const users = JSON.parse(xhr.response);
                USERS = users.map((user) => ({
                    id: user.id,
                    ip: user.ip,
                    mail: user.mail,
                    tryNumber: user.tryNumber,
                    done: user.done,
                }));
                USER = USERS.find((u) => u.mail && u.mail !== '' && u.mail === MAIL) || {
                    ip: IP,
                    mail: MAIL,
                    tryNumber: 0,
                    done: '0',
                };
                if (USER && USER.id) {
                    if (USER && USER.done === '1') {
                        createEvent({ mail: MAIL, done: '1' });
                    }
                    const myFunction = () => getUserQuiz(fetchAllUsersCount);
                    sendDataToUserSession(myFunction);
                    if (USER.tryNumber) {
                        TRY_NUMBER = +USER.tryNumber;
                    }
                }
                else {
                    fetchAllUsersCount();
                    TRY_NUMBER = USERS.filter((user) => !user.mail).length;
                }
            }
        }
    };
    xhr.setRequestHeader('Content-type', 'application/json'); // or "text/plain"
    xhr.send();
}
function getUserQuiz(callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', 'fetch-user-quiz.php', true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            if (xhr.response) {
                const data = JSON.parse(xhr.response);
                if (data && data[0]) {
                    USER.quizId = data[0].id;
                    QUIZ_SAVED_IN_DB_ID = data[0].id;
                }
                sendDataToUserSession(callback);
                // get last quiz saved
            }
        }
    };
    xhr.setRequestHeader('Content-type', 'application/json'); // or "text/plain"
    xhr.send();
}
function editUser(callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'update-user.php', true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            if (callback) {
                callback();
            }
        }
    };
    xhr.setRequestHeader('Content-type', 'application/json'); // or "text/plain"
    xhr.send(JSON.stringify(USER));
}
/**
 * initialise le formulaire pour la récuperations des données de l'utilisateur.
 */
function initializeBtnStart() {
    const btnStartQuizElement = document.querySelector('.start-quiz');
    if (btnStartQuizElement) {
        btnStartQuizElement.onclick = (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (USER && USER.id && USER.mail && USER.mail !== '') {
                if (TRY_NUMBER < MAX_TRY_NUMBER) {
                    TRY_NUMBER++;
                    USER.tryNumber = TRY_NUMBER;
                    const myFunction = () => editUser(initializeQuiz);
                    sendDataToUserSession(myFunction);
                }
                else {
                    displayModal("<p>Vous avez atteint le nombre maximum de participations à l'enquête.<br/>Nous vous remercions d'avoir participé.</p>", true);
                }
            }
            else {
                USER.tryNumber = 1;
                const callback = () => {
                    addUser(getUserLastInsert);
                };
                sendDataToUserSession(callback);
                COUNT++;
                if (USERS.length < MAX_TRY_NUMBER) {
                    initializeQuiz();
                }
                else {
                    displayModal("<p>Vous avez atteint le nombre maximum de participations à l'enquête.<br/>Nous vous remercions d'avoir participé.</p>", true);
                }
            }
        };
    }
    loader(false);
}
function loader(activate) {
    const loader = document.querySelector('#bloc-loader');
    if (loader) {
        if (activate) {
            loader.style.display = 'block';
        }
        else {
            loader.style.display = 'none';
        }
    }
}
/**
 * initialise le quiz en récupérant les données pour le questionnaire
 */
function initializeQuiz() {
    myData['data'].forEach((quiz) => {
        QUIZ.push({
            display: false,
            feedback: quiz.feedback,
            question: quiz.question,
            questionInnerText: quiz.questionInnerText,
            answers: setAnswers(quiz),
            multiple: !!quiz.multiple || quiz.answers.filter((answer) => { var _a; return ((_a = answer === null || answer === void 0 ? void 0 : answer.attributes) === null || _a === void 0 ? void 0 : _a.correct) === 'true'; }).length > 1,
            answersLimit: quiz.answersLimit || 0,
            validated: false,
        });
    });
    const preQuizElement = document.querySelector('#pre-quiz');
    if (preQuizElement) {
        preQuizElement.innerHTML = '';
    }
    const classesToAdd = [`quiz-started`];
    changeBodyBackground(classesToAdd, classesToAdd);
    addClassesFromElement('.logo-wrapper', ['col-lg-3']);
    const btnPrevious = document.querySelector('.btn-previous-stepper');
    const btnNext = document.querySelector('.btn-next-stepper');
    if (btnPrevious) {
        btnPrevious.onclick = (e) => previousQuestion(e);
    }
    if (btnNext) {
        btnNext.onclick = (e) => nextQuestion(e);
    }
    createAndRenderQuiz();
}
function answersChanged(answers) {
    const answerElementSelected = answers.filter((answer) => answer.getAttribute('selected') === 'true');
    const even = ANSWER_REGISTERED[STEP].answersSelected.length === answerElementSelected.length;
    return (!even ||
        answerElementSelected.some((answer) => answer.getAttribute('selected') === 'true' &&
            !ANSWER_REGISTERED[STEP].answersSelected.some((answerSelected) => {
                const customAnswerInput = answer === null || answer === void 0 ? void 0 : answer.querySelector('.user-custom-answer');
                if (answerSelected.customUserAnswer && customAnswerInput) {
                    return customAnswerInput.value === answerSelected.value;
                }
                return answerSelected.value === answer.getAttribute('value');
            })));
}
/**
 * passage au questionnaire suivant
 * @param event
 */
function nextQuestion(event, callback) {
    var _a, _b, _c, _d, _e, _f;
    event.preventDefault();
    event.stopPropagation();
    const answers = Array.prototype.slice.call(document.querySelectorAll('.answer'));
    if (!((_b = (_a = ANSWER_REGISTERED[STEP]) === null || _a === void 0 ? void 0 : _a.answersSelected) === null || _b === void 0 ? void 0 : _b.length) ||
        !answers.some((answer) => answer.getAttribute('selected') === 'true') ||
        (((_d = (_c = ANSWER_REGISTERED[STEP]) === null || _c === void 0 ? void 0 : _c.answersSelected) === null || _d === void 0 ? void 0 : _d.length) && answersChanged(answers))) {
        return;
    }
    if (callback) {
        callback();
    }
    if (!QUIZ[STEP].multiple && !QUIZ[STEP].validated) {
        QUIZ[STEP].validated = true;
    }
    if (isLastQuestion()) {
        changeBodyBackground(['quiz-ended'], ['quiz-started']);
        addClassesFromElement('.logo-wrapper', ['col-lg-3']);
        initializeDonation();
        initializeCarousel();
    }
    else {
        STEP++;
        createAndRenderQuiz();
        if (!!((_e = QUIZ[STEP]) === null || _e === void 0 ? void 0 : _e.validated)) {
            if (!!((_f = QUIZ[STEP]) === null || _f === void 0 ? void 0 : _f.multiple)) {
                testAnswer(event, true);
            }
            else {
                correction(event, true);
            }
        }
    }
}
/**
 * passage au questionnaire suivant
 * @param event
 */
function previousQuestion(event, callback, validateQuizBefore = false) {
    var _a, _b, _c, _d;
    event.preventDefault();
    event.stopPropagation();
    const answers = Array.prototype.slice.call(document.querySelectorAll('.answer'));
    if ((((_b = (_a = ANSWER_REGISTERED[STEP]) === null || _a === void 0 ? void 0 : _a.answersSelected) === null || _b === void 0 ? void 0 : _b.length) && answersChanged(answers)) || STEP === 0) {
        return;
    }
    if (!QUIZ[STEP].multiple && !QUIZ[STEP].validated) {
        QUIZ[STEP].validated = true;
    }
    if (callback) {
        callback();
    }
    if (validateQuizBefore) {
        QUIZ[STEP].validated = true;
    }
    STEP--;
    createAndRenderQuiz();
    if (!!((_c = QUIZ[STEP]) === null || _c === void 0 ? void 0 : _c.validated)) {
        if (!!((_d = QUIZ[STEP]) === null || _d === void 0 ? void 0 : _d.multiple)) {
            testAnswer(event, true);
        }
        else {
            correction(event, true);
        }
    }
}
/**
 * création du quiz et injection du code html
 */
function createAndRenderQuiz() {
    const quiz = QUIZ[STEP];
    const progressBarWrapperElements = Array.prototype.slice.call(document.querySelectorAll('.progress-bar-wrapper'));
    if (progressBarWrapperElements.length) {
        progressBarWrapperElements.forEach((item) => {
            const widthMask = `${STEP === QUIZ.length - 1 ? 100 : Math.round((100 / QUIZ.length) * (STEP + 1))}`;
            const questionNumberWrapper = item.querySelector('.question-number-info');
            questionNumberWrapper.innerHTML = `<span>${STEP + 1} /</span>&nbsp;<span>${QUIZ.length}</span>`;
            questionNumberWrapper.style.width = widthMask + '%';
            if (widthMask === '100') {
                questionNumberWrapper.style.borderTopRightRadius = '4px';
                questionNumberWrapper.style.borderBottomRightRadius = '4px';
            }
        });
    }
    const quizContainer = document.querySelector('#quiz-container');
    if (quizContainer) {
        quizContainer.innerHTML = '';
    }
    const question = `<div class="question-wrapper col-12 col-md-11 col-lg-9 mx-auto"><label class="question-number"><span>${STEP + 1}</span><span class="icon question-arrow-right"></span></label>${quiz.question}</div>`;
    // inject question
    quizContainer === null || quizContainer === void 0 ? void 0 : quizContainer.insertAdjacentHTML('beforeend', question);
    // create form for quiz
    const formConf = {
        attributes: {
            noValidate: '',
        },
        tagName: 'form',
        classList: ['form-quiz', 'col-12', 'col-md-11', 'col-lg-9', 'mx-auto'],
    };
    const form = new Dynamically_create_element(formConf).create();
    // create wrappers for one or two column inside form
    const answersWrapperConf = {
        tagName: 'div',
        classList: ['answers-wrapper', 'row'],
    };
    const answersWrapper1 = new Dynamically_create_element(answersWrapperConf).create();
    // inject answers
    quiz.answers.forEach((answer) => {
        answersWrapper1.appendChild(answer);
    });
    form.appendChild(answersWrapper1);
    quizContainer === null || quizContainer === void 0 ? void 0 : quizContainer.appendChild(form);
    if (quiz.multiple) {
        createBtnTestAnswer();
        const answers = Array.prototype.slice.call(document.querySelectorAll('.answer'));
        answers.forEach((answer) => {
            const inputs = Array.prototype.slice.call(answer.querySelectorAll('input'));
            const inputElement = answer === null || answer === void 0 ? void 0 : answer.querySelector('.user-custom-answer');
            if (inputElement) {
                inputElement.addEventListener('change', (event) => {
                    const elem = event.target;
                    if (!elem.value || (elem.value && elem.value === '')) {
                        answer.setAttribute('selected', 'false');
                        answer.classList.remove('selected');
                        inputs.forEach((input) => {
                            if (input) {
                                input.checked = false;
                            }
                        });
                    }
                    else {
                        answer.setAttribute('selected', 'true');
                        answer.classList.add('selected');
                        inputs.forEach((input) => {
                            if (input) {
                                input.checked = true;
                            }
                        });
                    }
                });
                inputElement.addEventListener('focusout', (event) => {
                    const elem = event.target;
                    if (!elem.value || (elem.value && elem.value === '')) {
                        answer.setAttribute('selected', 'false');
                        answer.classList.remove('selected');
                        inputs.forEach((input) => {
                            if (input) {
                                input.checked = false;
                            }
                        });
                    }
                    else {
                        answer.setAttribute('selected', 'true');
                        answer.classList.add('selected');
                        inputs.forEach((input) => {
                            if (input) {
                                input.checked = true;
                            }
                        });
                    }
                });
            }
        });
    }
    setBtnPreviousDisabled();
    setBtnNextDisabled();
}
function setBtnPreviousDisabled() {
    var _a, _b;
    const answers = Array.prototype.slice.call(document.querySelectorAll('.answer'));
    const btnPrevious = document.querySelector('.btn-previous-stepper');
    if (btnPrevious) {
        if ((((_b = (_a = ANSWER_REGISTERED[STEP]) === null || _a === void 0 ? void 0 : _a.answersSelected) === null || _b === void 0 ? void 0 : _b.length) && answersChanged(answers)) || STEP === 0) {
            btnPrevious.classList.remove('active');
        }
        else if (!btnPrevious.classList.contains('active')) {
            btnPrevious.classList.add('active');
        }
    }
}
function setBtnNextDisabled() {
    var _a, _b, _c, _d;
    const answers = Array.prototype.slice.call(document.querySelectorAll('.answer'));
    const btnNext = document.querySelector('.btn-next-stepper');
    if (btnNext) {
        if (isLastQuestion() ||
            !((_b = (_a = ANSWER_REGISTERED[STEP]) === null || _a === void 0 ? void 0 : _a.answersSelected) === null || _b === void 0 ? void 0 : _b.length) ||
            !answers.some((answer) => answer.getAttribute('selected') === 'true') ||
            (((_d = (_c = ANSWER_REGISTERED[STEP]) === null || _c === void 0 ? void 0 : _c.answersSelected) === null || _d === void 0 ? void 0 : _d.length) && answersChanged(answers))) {
            btnNext.classList.remove('active');
        }
        else if (!btnNext.classList.contains('active')) {
            btnNext.classList.add('active');
        }
    }
}
/**
 * change le background selon les étapes du quiz
 * @param classesToAdd
 * @param classesToRemove
 */
function changeBodyBackground(classesToAdd, classesToRemove) {
    if (!!(classesToRemove === null || classesToRemove === void 0 ? void 0 : classesToRemove.length)) {
        removeClassesFromElement('body', classesToRemove);
    }
    addClassesFromElement('body', classesToAdd);
}
/**
 * utilitaire pour supprimer une ou plusieurs classes css d'un element html
 * @param selector
 * @param classes
 */
function removeClassesFromElement(selector, classes) {
    var _a, _b;
    const isSelectorAClass = selector.includes('.');
    const isSelectorAnId = selector.includes('#');
    const isSelectorATag = !selector.includes('.') && !selector.includes('#');
    if (isSelectorAClass) {
        const elements = document.querySelectorAll(selector);
        Array.prototype.slice.call(elements).forEach((element) => { var _a; return (_a = element.classList) === null || _a === void 0 ? void 0 : _a.remove(...classes); });
    }
    if (isSelectorAnId || isSelectorATag) {
        (_b = (_a = document.querySelector(selector)) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.remove(...classes);
    }
}
/**
 * utilitaire pour ajouter une ou plusieurs classes css à un element html
 * @param selector
 * @param classes
 */
function addClassesFromElement(selector, classes) {
    var _a, _b;
    const isSelectorAClass = selector.includes('.');
    const isSelectorATag = !selector.includes('.') && !selector.includes('#');
    const isSelectorAnId = selector.includes('#');
    if (isSelectorAClass) {
        const elements = document.querySelectorAll(selector);
        Array.prototype.slice.call(elements).forEach((element) => { var _a; return (_a = element === null || element === void 0 ? void 0 : element.classList) === null || _a === void 0 ? void 0 : _a.add(...classes); });
    }
    if (isSelectorAnId || isSelectorATag) {
        (_b = (_a = document.querySelector(selector)) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.add(...classes);
    }
}
/**
 * correction du quiz avec ajout des effets visuel via les classes css
 * @param event
 * @param reviewQuiz sert à savoir si l'utilisateur regarde un quiz deja corrigé
 */
function correction(event, reviewQuiz = false) {
    var _a, _b;
    let currentElement = !reviewQuiz ? event === null || event === void 0 ? void 0 : event.currentTarget : undefined;
    const answers = Array.prototype.slice.call(document.querySelectorAll('.answer'));
    if (!reviewQuiz && !!((_a = QUIZ[STEP]) === null || _a === void 0 ? void 0 : _a.validated)) {
        return;
    }
    if (reviewQuiz) {
        QUIZ[STEP].validated = false;
        const answersRegistered = ANSWER_REGISTERED[STEP];
        currentElement = answers.find((answerElement) => { var _a; return answerElement.getAttribute('value') === ((_a = answersRegistered === null || answersRegistered === void 0 ? void 0 : answersRegistered.answersSelected[0]) === null || _a === void 0 ? void 0 : _a.value); });
    }
    else {
        QUIZ[STEP].validated = true;
    }
    answers.forEach((answerElement) => {
        answerElement.classList.remove('selected');
        answerElement.setAttribute('validated', 'false');
        answerElement.setAttribute('selected', 'false');
    });
    if (currentElement) {
        currentElement.setAttribute('validated', 'true');
        currentElement.setAttribute('selected', 'true');
        currentElement.classList.add('selected');
        currentElement.getElementsByTagName('input')[0].checked = true;
    }
    const answerFeedback = (_b = myData.data[STEP].answers.find((answer) => answer.attributes.value === (currentElement === null || currentElement === void 0 ? void 0 : currentElement.getAttribute('value')))) === null || _b === void 0 ? void 0 : _b.feedback;
    if (!reviewQuiz) {
        const callback = () => {
            setTimeout(() => {
                var _a, _b;
                if ((answerFeedback === null || answerFeedback === void 0 ? void 0 : answerFeedback.default) !== '') {
                    displayModal(answerFeedback === null || answerFeedback === void 0 ? void 0 : answerFeedback.default);
                }
                else if (((_a = myData.data[STEP]) === null || _a === void 0 ? void 0 : _a.feedback.default) !== '') {
                    displayModal((_b = myData.data[STEP]) === null || _b === void 0 ? void 0 : _b.feedback.default);
                }
                else {
                    nextQuestion(event);
                }
            }, TIMEOUT_MODAL);
        };
        saveAnswerSelected(answers, callback);
    }
}
/**
 * test les réponses de l'utilisateur en cas de plusieurs bonnes reponses possible
 * @param e MouseEvent
 */
function testAnswer(e, reviewQuiz = false) {
    e.preventDefault();
    e.stopPropagation();
    const answers = Array.prototype.slice.call(document.querySelectorAll('.answer'));
    if ((!reviewQuiz && !!QUIZ[STEP].validated) ||
        !answers.some((answer) => answer.getAttribute('selected') === 'true')) {
        return;
    }
    if (reviewQuiz) {
        QUIZ[STEP].validated = false;
        const answersRegistered = ANSWER_REGISTERED[STEP];
        answers.forEach((answerElement) => {
            const inputElement = answerElement === null || answerElement === void 0 ? void 0 : answerElement.querySelector('.user-custom-answer');
            answersRegistered === null || answersRegistered === void 0 ? void 0 : answersRegistered.answersSelected.forEach((answer) => {
                if (inputElement && answer.customUserAnswer) {
                    inputElement.setAttribute('value', answer.value || '');
                    answerElement.setAttribute('selected', 'true');
                }
                else {
                    if (answerElement.value === answer.value) {
                        answerElement.setAttribute('selected', 'true');
                    }
                }
            });
        });
    }
    else {
        QUIZ[STEP].validated = true;
    }
    // met les classes css pour le feedback de la correction
    answers.forEach((answer) => {
        if (answer.getAttribute('selected') === 'true') {
            answer.setAttribute('validated', 'true');
        }
        else {
            answer.setAttribute('validated', 'false');
        }
    });
    if (!reviewQuiz) {
        const callback = () => {
            setTimeout(() => {
                var _a, _b;
                if (((_a = myData.data[STEP]) === null || _a === void 0 ? void 0 : _a.feedback.default) !== '') {
                    displayModal((_b = myData.data[STEP]) === null || _b === void 0 ? void 0 : _b.feedback.default);
                }
                else {
                    nextQuestion(e);
                }
            }, TIMEOUT_MODAL);
        };
        saveAnswerSelected(answers, callback);
    }
}
function saveAllAnswers(callback) {
    if (ANSWER_REGISTERED.length === QUIZ.length) {
        USER.done = '1';
        if (MAIL && MAIL !== '') {
            createEvent({ mail: MAIL, done: '1' });
        }
        sendDataToUserSession(editUser);
    }
    const data = {
        uid: USER === null || USER === void 0 ? void 0 : USER.id,
    };
    ANSWER_REGISTERED.forEach((quiz, index) => {
        // @ts-ignore
        data[`question-${index + 1}`] = quiz.answersSelected.map((answer) => answer.value).join(' | ');
    });
    if (QUIZ_SAVED_IN_DB_ID || QUIZ_SAVED_IN_DB_ID === 0) {
        updateQuiz(data, callback);
    }
    else {
        saveQuiz(data, callback);
    }
}
function saveQuiz(quiz, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'create-quiz.php', true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            // todo il faut recuperer les info de la reponse pour avoir l'id de l'user
            getUserQuiz(callback);
        }
    };
    xhr.setRequestHeader('Content-type', 'application/json'); // or "text/plain"
    xhr.send(JSON.stringify(quiz));
}
function updateQuiz(quiz, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'update-quiz.php', true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            // réponses sauvegardés
            callback();
        }
    };
    xhr.setRequestHeader('Content-type', 'application/json'); // or "text/plain"
    xhr.send(JSON.stringify(quiz));
}
/**
 * permet d'ajouter une classe css pour l'etat selectionné ou deselectionné en cas de plusieurs réponses possibles
 * @param event
 */
function selectionForMultipleAnswers(event) {
    event.stopPropagation();
    event.preventDefault();
    if (!!QUIZ[STEP].validated) {
        return;
    }
    const answers = Array.prototype.slice.call(document.querySelectorAll('.answer'));
    const answersSelected = answers === null || answers === void 0 ? void 0 : answers.filter((answer) => answer.getAttribute('selected') === 'true');
    const currentElement = event.currentTarget;
    const inputs = Array.prototype.slice.call(currentElement.querySelectorAll('input'));
    const inputElement = currentElement === null || currentElement === void 0 ? void 0 : currentElement.querySelector('.user-custom-answer');
    if (inputElement) {
        inputElement.focus();
    }
    if (currentElement.getAttribute('selected') === 'true') {
        if (!(inputElement && inputElement.value && inputElement.value !== '')) {
            inputs.forEach((input) => {
                if (input) {
                    input.checked = false;
                }
            });
            currentElement.setAttribute('selected', 'false');
            currentElement.classList.remove('selected');
        }
    }
    else {
        if (!QUIZ[STEP].answersLimit ||
            (QUIZ[STEP].answersLimit > 0 && answersSelected.length < QUIZ[STEP].answersLimit)) {
            inputs.forEach((input) => {
                if (input) {
                    input.checked = true;
                }
            });
            currentElement.setAttribute('selected', 'true');
            currentElement.classList.add('selected');
        }
    }
}
/**
 * Enregistrement des réponses de l'utilisateur
 * @param answers
 */
function saveAnswerSelected(answers, callback) {
    ANSWER_REGISTERED.splice(STEP, 1, {
        question: QUIZ[STEP].questionInnerText,
        answersSelected: answers
            .filter((answerElement) => answerElement.getAttribute('validated') === 'true')
            .map((answerElement) => {
            const inputElement = answerElement === null || answerElement === void 0 ? void 0 : answerElement.querySelector('.user-custom-answer');
            if (inputElement) {
                return {
                    value: inputElement === null || inputElement === void 0 ? void 0 : inputElement.value,
                    customUserAnswer: true,
                };
            }
            return {
                value: answerElement.getAttribute('value'),
            };
        }),
    });
    saveAllAnswers(callback);
}
function getWrapperBtnElement() {
    const quizContainer = document.querySelector('#quiz-container');
    const wrapperBtnAlreadyCreated = document.querySelector('#wrapper-btn');
    if (!wrapperBtnAlreadyCreated) {
        const wrapperBtnConf = {
            attributes: {
                id: 'wrapper-btn',
            },
            classList: ['d-flex', 'justify-content-center'],
            innerHTML: '',
            tagName: 'div',
        };
        const wrapperBtnElement = new Dynamically_create_element(wrapperBtnConf).create();
        quizContainer === null || quizContainer === void 0 ? void 0 : quizContainer.appendChild(wrapperBtnElement);
        return wrapperBtnElement;
    }
    return wrapperBtnAlreadyCreated;
}
/**
 * création du bouton suivant
 */
function createBtnNext(callback) {
    const btnNextAlreadyCreated = Array.prototype.slice.call(document.querySelectorAll('.btn-next'));
    if (!!btnNextAlreadyCreated.length) {
        btnNextAlreadyCreated.forEach((btn) => btn.remove());
    }
    const btnNextConf = {
        attributes: {
            name: 'btn-next',
            type: 'button',
            value: 'Question Suivante',
        },
        classList: ['btn-next-question', 'btn-action'],
        innerHTML: `<label>Question suivante</label>`,
        onclick: (e) => nextQuestion(e, callback),
        tagName: 'button',
    };
    return new Dynamically_create_element(btnNextConf).create();
}
function isLastQuestion() {
    return STEP === QUIZ.length - 1;
}
/**
 * création du bouton "Tester ma réponse"
 */
function createBtnTestAnswer() {
    const btnTestAnswerAlreadyCreated = Array.prototype.slice.call(document.querySelectorAll('.btn-test-answer'));
    if (!!btnTestAnswerAlreadyCreated.length) {
        btnTestAnswerAlreadyCreated.forEach((btn) => btn.remove());
    }
    const btnTestAnswerConf = {
        attributes: {
            name: 'btn-test-answer',
            type: 'submit',
            value: 'Voir la réponse',
        },
        classList: ['mx-auto', 'btn-test-answer', 'btn-action'],
        innerHTML: '<label>Je valide mes réponses</label>',
        onclick: (e) => testAnswer(e),
        tagName: 'button',
    };
    const btnTestAnswer = new Dynamically_create_element(btnTestAnswerConf).create();
    getWrapperBtnElement().appendChild(btnTestAnswer);
}
/**
 * récupère les réponses du quiz depuis les data et crée-les elements HTML
 * @param quiz
 */
function setAnswers(quiz) {
    const answersLength = quiz.answers.length;
    return quiz.answers.map((answer, index) => {
        const data = {
            classList: answer.classList,
            innerHTML: `<div class="answer-space-wrapper">${answer.innerHTML}</div>`,
            tagName: answer.tagName,
            onclick: (e) => (!!quiz.multiple ? selectionForMultipleAnswers(e) : correction(e)),
            attributes: answer.attributes,
        };
        if (answersLength > 4 && index < answersLength - 1) {
            data.classList =
                index % 2 === 0 ? [...answer.classList, 'px-right-md-2'] : [...answer.classList, 'px-left-md-2'];
        }
        return new Dynamically_create_element(data).create();
    });
}
/**
 * utilitaire permettant de récupérer les params de l'url
 * @param url
 */
function getAllUrlParams(url) {
    // get query string from url (optional) or window
    let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    // we'll store the parameters here
    const obj = {};
    // if query string exists
    if (queryString) {
        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];
        // split our query string into its component parts
        const arr = queryString.split('&');
        for (let i = 0; i < arr.length; i++) {
            // separate the keys and the values
            const a = arr[i].split('=');
            // set parameter name and value (use 'true' if empty)
            let paramName = a[0];
            let paramValue = typeof a[1] === 'undefined' ? true : a[1];
            // (optional) keep case consistent
            paramName = paramName.toLowerCase();
            if (typeof paramValue === 'string')
                paramValue = paramValue.toLowerCase();
            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {
                // create key if it doesn't exist
                const key = paramName.replace(/\[(\d+)?\]/, '');
                if (!obj[key])
                    obj[key] = [];
                // otherwise add the value to the end of the array
                obj[key].push(paramValue);
            }
            else {
                // we're dealing with a string
                if (!obj[paramName]) {
                    // if it doesn't exist, create property
                    obj[paramName] = paramValue;
                }
                else if (obj[paramName] && typeof obj[paramName] === 'string') {
                    // if property does exist, and it's a string, convert it to an array
                    obj[paramName] = [obj[paramName]];
                    obj[paramName].push(paramValue);
                }
                else {
                    // otherwise add the property
                    obj[paramName].push(paramValue);
                }
            }
        }
    }
    return obj;
}
function createEvent(data) {
    // To assign event
    const event = new CustomEvent('brevo', {
        detail: data,
    });
    // To trigger the Event
    document.dispatchEvent(event);
}
function createModal() {
    const modalHtml = document.getElementById('modal');
    return new Modal(modalHtml || '', {
        keyboard: false,
    });
}
function displayModal(feedback = '', modalNotUsedForQuiz = false) {
    const modalHtml = document.getElementById('modal');
    const modalHeaderHtml = document.querySelector('.modal-header');
    const modalBodyHtml = document.getElementById('modal-body');
    const modalFooterHtml = document.querySelector('.modal-footer');
    if (modalHtml && modalBodyHtml && modalHeaderHtml && modalFooterHtml) {
        const btnCloseModalAlreadyCreated = modalFooterHtml.querySelector('.btn-close-modal');
        const btnNextModalAlreadyCreated = modalFooterHtml.querySelector('.btn-next-question');
        modalHeaderHtml.innerHTML = '';
        modalBodyHtml.innerHTML = feedback;
        if (isLastQuestion() || modalNotUsedForQuiz) {
            modalHeaderHtml.insertAdjacentHTML('beforeend', '<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>');
            if (!btnCloseModalAlreadyCreated) {
                modalFooterHtml.insertAdjacentHTML('afterbegin', '<button type="button" class="btn-action btn-close-modal btn" data-bs-dismiss="modal" aria-label="Close">Fermer la fenêtre</button>');
            }
        }
        else {
            if (!btnNextModalAlreadyCreated) {
                modalBodyHtml.appendChild(createBtnNext(() => MODAL.hide()));
            }
        }
        modalHtml === null || modalHtml === void 0 ? void 0 : modalHtml.addEventListener('hide.bs.modal', () => {
            if (isLastQuestion() && !modalNotUsedForQuiz) {
                changeBodyBackground(['quiz-ended'], ['quiz-started']);
                addClassesFromElement('.logo-wrapper', ['col-lg-3']);
                initializeDonation();
            }
        });
        modalHtml === null || modalHtml === void 0 ? void 0 : modalHtml.addEventListener('shown.bs.modal', () => { });
        MODAL.show();
    }
}
function initializeDonation() {
    const sectionQuizElement = document.querySelector('#section-quiz');
    if (sectionQuizElement) {
        sectionQuizElement.innerHTML = '';
    }
    const btnDonate = document.querySelector('#btn-donation');
    const btnDonateMobile = document.querySelector('#btn-donation-mobile');
    const btnDonationElements = Array.prototype.slice.call(document.querySelectorAll('.wrapper-don'));
    if (btnDonate) {
        btnDonationElements.forEach((elem) => {
            if (elem.getAttribute('selected') === 'true') {
                DONATION = '100';
            }
        });
        btnDonate.onclick = () => donate();
    }
    if (btnDonateMobile) {
        btnDonateMobile.onclick = () => donate(true);
    }
    btnDonationElements.forEach((btn) => {
        var _a;
        btn.onclick = (e) => selectDonation(e);
        const reductionFiscalElement = btn.querySelector('.reduction-fiscale');
        if (btn.getAttribute('value') === 'custom') {
            (_a = btn.querySelector('input')) === null || _a === void 0 ? void 0 : _a.addEventListener('change', (event) => {
                const elem = event.target;
                if (reductionFiscalElement) {
                    reductionFiscalElement.innerHTML =
                        elem.value && elem.value !== '' ? `${Math.round((parseInt(elem.value) * 66) / 100)}€` : '--';
                    DONATION = elem.value && elem.value !== '' ? elem === null || elem === void 0 ? void 0 : elem.value : null;
                }
            });
        }
        else {
            if (reductionFiscalElement) {
                const don = btn.getAttribute('value') || '0';
                reductionFiscalElement.innerHTML = `${Math.round((parseInt(don) * 66) / 100)}€`;
            }
        }
    });
}
function selectDonation(event) {
    var _a;
    const btnDonationElements = Array.prototype.slice.call(document.querySelectorAll('.wrapper-don'));
    btnDonationElements.forEach((btn) => {
        btn.classList.remove('selected');
        btn.setAttribute('selected', 'false');
    });
    const currentElement = event.currentTarget;
    (_a = currentElement === null || currentElement === void 0 ? void 0 : currentElement.classList) === null || _a === void 0 ? void 0 : _a.add('selected');
    currentElement.setAttribute('selected', 'true');
    if (currentElement.getAttribute('value') !== 'custom') {
        DONATION = currentElement.getAttribute('value');
    }
    else {
        const input = currentElement.querySelector('input');
        DONATION = input === null || input === void 0 ? void 0 : input.value;
    }
}
function donate(isFormatMobile = false) {
    let queryParams = Object.assign({}, _.cloneDeep(PARAMS_FROM_URL));
    if (!isFormatMobile && DONATION && DONATION !== '') {
        queryParams = Object.assign(Object.assign({}, _.cloneDeep(PARAMS_FROM_URL)), { engagement: 'don_simple', montant: DONATION });
    }
    let queryParamsUrlFormat = '';
    for (const key in queryParams) {
        // @ts-ignore
        if (queryParams[key] && queryParams[key] !== 'undefined') {
            // @ts-ignore
            const val = queryParams[key];
            // @ts-ignore
            queryParamsUrlFormat =
                queryParamsUrlFormat + (queryParamsUrlFormat.includes('?') ? '&' : '?') + key + '=' + val;
        }
    }
    const url = (DONATION && DONATION !== '' ? URL_DON + '/fill' : URL_DON) + queryParamsUrlFormat;
    window.open(url, '_blank');
}
function initializeCarousel() {
    $('.carousel-wrapper').not('.slick-initialized').slick({
        arrows: false,
        centerMode: false,
        centerPadding: '60px',
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '80px',
                    slidesToShow: 1,
                    initialSlide: 0,
                },
            },
        ],
    });
    const plusElementHtml = Array.prototype.slice.call(document.querySelectorAll('.sub-item-carousel'));
    plusElementHtml.forEach((btn) => {
        btn.onclick = () => {
            const name = btn.getAttribute('name');
            // Error EsLint so i cant write simply displayModal(myData.carousel[name]) ...
            let key;
            for (key in myData === null || myData === void 0 ? void 0 : myData.carousel) {
                if (key === name) {
                    displayModal(myData.carousel[name], true);
                }
            }
        };
    });
}
function initBtnSocialMedia() {
    const allBtnFacebook = Array.prototype.slice.call(document.querySelectorAll('.facebook'));
    const allBtnTwitter = Array.prototype.slice.call(document.querySelectorAll('.twitter'));
    const allBtnWhatsapp = Array.prototype.slice.call(document.querySelectorAll('.whatsapp'));
    allBtnFacebook.forEach((link) => {
        const window_size = 'width=585,height=368';
        const url = 'https://www.facebook.com/sharer/sharer.php?&u=https://enquete.fnh.org/';
        if (link) {
            link.onclick = () => window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,' + window_size);
        }
    });
    allBtnTwitter.forEach((link) => {
        const window_size = 'width=585,height=261';
        const url = "https://twitter.com/intent/tweet?text=''&url=https://enquete.fnh.org/";
        if (link) {
            link.onclick = () => window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,' + window_size);
        }
    });
    allBtnWhatsapp.forEach((link) => {
        const window_size = 'width=585,height=261';
        const url = 'https://api.whatsapp.com/send?text=https://enquete.fnh.org/';
        if (link) {
            link.onclick = () => window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,' + window_size);
        }
    });
}
